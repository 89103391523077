// extracted by mini-css-extract-plugin
export var bank__details = "payment-module--bank__details--kLGRr";
export var content__info = "payment-module--content__info--RHKX1";
export var content__payments = "payment-module--content__payments--A4mC4";
export var content__total_payment = "payment-module--content__total_payment--QVwJG";
export var description = "payment-module--description--k8tbO";
export var info__main = "payment-module--info__main--XXmOM";
export var info__second = "payment-module--info__second--Uf9zs";
export var payment = "payment-module--payment--jxeZ6";
export var payment__loading = "payment-module--payment__loading--WbqxI";
export var payment__title = "payment-module--payment__title--wWwMG";
export var payments = "payment-module--payments--8fyli";
export var payments__form = "payment-module--payments__form--L4OG7";
export var payments__item = "payment-module--payments__item--jMxOF";
export var payments__title = "payment-module--payments__title--XyKpW";
export var price = "payment-module--price--YruHU";
export var students__payments = "payment-module--students__payments--TWwRe";